
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFirstdraft } from '@fortawesome/free-brands-svg-icons';
import { faAddressBook, faFile, faMessage as faMessageRegular, faTrashCan } from '@fortawesome/free-regular-svg-icons';

import {
  faAddressCard,
  faAnglesLeft,
  faArrowsRotate,
  faArrowUpRightFromSquare,
  faBars,
  faBell,
  faCalendarDay,
  faCheck,
  faCheckDouble,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleNotch,
  faCircleXmark,
  faClipboard,
  faClipboardCheck,
  faClock,
  faComment,
  faComments,
  faCopy,
  faCreditCard,
  faCrown,
  faDownload,
  faEarthAmerica,
  faEdit,
  faEllipsisVertical,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faEnvelopesBulk,
  faEye,
  faEyeSlash,
  faFaceSmile,
  faFileAlt,
  faFileArrowDown,
  faFileCircleQuestion,
  faFileContract,
  faFilter,
  faFlag,
  faFlask,
  faFloppyDisk,
  faGears,
  faIdCard,
  faImages,
  faInbox,
  faLightbulb,
  faMessage,
  faMicrochip,
  faMinus,
  faNotesMedical,
  faPager,
  faPaperclip,
  faPencil,
  faPeopleGroup,
  faPlus,
  faQuestion,
  faRetweet,
  faRobot,
  faRotateLeft,
  faRotateRight,
  faSearch,
  faSquarePollHorizontal,
  faStar,
  faStethoscope,
  faTable,
  faTableColumns,
  faThList,
  faTimes,
  faTrashAlt,
  faTriangleExclamation,
  faTruck,
  faUser,
  faUserCircle,
  faUserDoctor,
  faUserEdit,
  faUsers,
  faUsersGear,
  faVial,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Component, Prop, Vue } from 'vue-property-decorator';

library.add(
  faAddressBook,
  faAddressCard,
  faAnglesLeft,
  faArrowUpRightFromSquare,
  faArrowsRotate,
  faBars,
  faBell,
  faCalendarDay,
  faCheck,
  faCheckDouble,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleNotch,
  faCircleXmark,
  faClipboard,
  faClipboardCheck,
  faClock,
  faComment,
  faComments,
  faCopy,
  faCreditCard,
  faCrown,
  faDownload,
  faEarthAmerica,
  faEdit,
  faEllipsisVertical,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faEnvelopesBulk,
  faEye,
  faEyeSlash,
  faFaceSmile,
  faFile,
  faFileAlt,
  faFileArrowDown,
  faFileCircleQuestion,
  faFileContract,
  faFilter,
  faFirstdraft,
  faFlag,
  faFlask,
  faFloppyDisk,
  faGears,
  faIdCard,
  faImages,
  faInbox,
  faLightbulb,
  faMessage,
  faMessageRegular,
  faMicrochip,
  faMinus,
  faNotesMedical,
  faPager,
  faPaperclip,
  faPencil,
  faPeopleGroup,
  faPlus,
  faQuestion,
  faRetweet,
  faRobot,
  faRotateLeft,
  faRotateRight,
  faSearch,
  faSquarePollHorizontal,
  faStar,
  faStethoscope,
  faTable,
  faTableColumns,
  faThList,
  faTimes,
  faTrashAlt,
  faTrashCan,
  faTriangleExclamation,
  faTruck,
  faUser,
  faUserCircle,
  faUserDoctor,
  faUserEdit,
  faUsers,
  faUsersGear,
  faVial,
  faWindowClose
);

@Component({
  components: { FontAwesomeIcon },
})
export default class FontAwesome extends Vue {
  @Prop() private name!: string | string[];
  @Prop({ default: false }) private fixedWidth!: boolean;
  @Prop() private extraClass: Record<string, boolean> | string[] | string;
}
