import gql from 'graphql-tag';

const STAFFER = gql`
  fragment Staffer on StafferType {
    id
    createdAt
    updatedAt

    firstName
    lastName
    displayName
    displayNameShort
    displayRole
    title
    npi

    phone
    email
    role
    state

    isAvailableAsMessagingAssignee
    isActive

    ehrBetaFeatures

    personalCalendlyLink
    personalZoomLink

    teams {
      id
    }
  }
`;

export { STAFFER };
