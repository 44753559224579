export enum Laboratory {
  unknown = 'Unknown',
  pre_existing = 'Pre-existing',
  quest_lab_corp = 'Quest/LabCorp',
  quest = 'Quest',
  lab_corp = 'LabCorp',
  biotek = 'BioTek',
  scarlet = 'Scarlet',
  getlabs = 'Getlabs',
  exam_one = 'ExamOne',
  seven_drops = '7Drops',
  spiriplex = 'SPX',
  other = 'Other',
}

export const getLaboratoryName = (laboratory: string | null): Laboratory => {
  if (!laboratory) {
    return Laboratory.unknown;
  }
  return Laboratory[laboratory as keyof typeof Laboratory];
};

export const laboratoryOptions = Object.entries(Laboratory).map(([key, value]) => ({
  text: value,
  value: key,
}));
