
import { Component, Vue } from 'vue-property-decorator';

import TimedCardText from '@/components/TimedCardText.vue';
import gql from 'graphql-tag';

@Component({
  components: {
    TimedCardText,
  },
  apollo: {
    patientStripePayments: {
      query: gql`
        query GetPatientStripePayments($patientId: UUID!) {
          patientStripePayments(patientId: $patientId) {
            id
            isRefund
            description
            amount
            createdAt
            linkToStripe
          }
        }
      `,
      fetchPolicy: 'network-only',
      variables() {
        return { patientId: this.$route.query?.['patient-id'] || this.$route.params.pk };
      },
    },
  },
})
export default class StripePaymentsCard extends Vue {
  patientStripePayments?: undefined;
}
