import type { PatientType, StafferType } from '@/types';
import { type MaybeRef, reactiveComputed } from '@vueuse/core';
import { computed, unref } from 'vue';
import { useStaffersStore } from '@/stores/staffers';

export type UseCalendlyLinksOptions = {
  staffer?: StafferType;
  mode?: 'append' | 'override';
  superStafferIds?: StafferType['id'][];
};
export function useCalendlyLinks(patient: MaybeRef<PatientType>, options?: MaybeRef<UseCalendlyLinksOptions>) {
  const staffersStore = useStaffersStore();
  const defaultSuperStafferIds: StafferType['id'][] = [];
  const salesforceUuids = {
    personalInsuranceFollowUp: 'salesforce_uuid=insurance-followup', //personal link followUpInsurance
    insuranceFollowUp: 'salesforce_uuid=insurance-followup', //followUpInsurance
    insuranceInitial: 'salesforce_uuid=insurance-initial', //initialInsurance
    subscriptionPreclinical: 'salesforce_uuid=subscription-preclin', //initial,
  };

  const superStafferIds = computed(() => {
    if (!unref(options)?.superStafferIds?.length) {
      return defaultSuperStafferIds;
    }

    return unref(options)?.mode === 'override'
      ? [...unref(options).superStafferIds]
      : [...defaultSuperStafferIds, ...unref(options).superStafferIds];
  });
  // Watching one prop is cheaper than watch whole patient object with deep: true
  const patientStafferId = computed(
    () => unref(options)?.staffer?.id || unref(patient)?.physician?.id || staffersStore.currentStaffer.id
  );
  const patientState = computed(() => unref(patient)?.state || '');
  const isSuperStaffer = computed(() => superStafferIds.value.includes(patientStafferId.value));

  function getHostname(name: string) {
    return isSuperStaffer.value || name === 'personalInsuranceFollowUp'
      ? staffersStore.staffers.find((staffer) => staffer.id === patientStafferId.value).personalCalendlyLink
      : `https://calendly.com/curex-allergists/allergy-immunotherapy-screening-${patientState.value.toLowerCase()}`;
  }

  function buildURL(name: string, query: string): string {
    if (isSuperStaffer.value && name === 'personalInsuranceFollowUp') {
      return;
    }

    return `${getHostname(name)}?${query}%7C${unref(patient).id}`;
  }

  return reactiveComputed(() =>
    Object.entries(salesforceUuids).reduce(
      (urls, [name, query]) => ({ ...urls, [name]: buildURL(name, query) }),
      {} as typeof salesforceUuids
    )
  );
}
