export enum PaymentPeriodEnum {
  monthly = 'Monthly',
  quarterly = 'Quarterly',
  semi_annual = 'Semi-annual',
  annual = 'Annual',
  threeyear = '3 year',
  unknown = 'Unknown',
}

export const paymentPeriodOptions = Object.entries(PaymentPeriodEnum).map(([key, value]) => ({
  text: value,
  value: key,
}));

import { PaymentOptionEnum } from '@/types/PaymentOptionEnum';

export const paymentPeriodPriceMap = {
  [PaymentPeriodEnum.monthly]: {
    [PaymentOptionEnum.insurance]: 49,
    [PaymentOptionEnum.self_pay]: 99,
    [PaymentPeriodEnum.unknown]: 0,
  },
  [PaymentPeriodEnum.quarterly]: {
    [PaymentOptionEnum.insurance]: 147,
    [PaymentOptionEnum.self_pay]: 297,
    [PaymentPeriodEnum.unknown]: 0,
  },
  [PaymentPeriodEnum.semi_annual]: {
    [PaymentOptionEnum.insurance]: 294,
    [PaymentOptionEnum.self_pay]: 594,
    [PaymentPeriodEnum.unknown]: 0,
  },
  [PaymentPeriodEnum.annual]: {
    [PaymentOptionEnum.insurance]: 490,
    [PaymentOptionEnum.self_pay]: 990,
    [PaymentPeriodEnum.unknown]: 0,
  },
  [PaymentPeriodEnum.threeyear]: {
    [PaymentOptionEnum.insurance]: 1470,
    [PaymentOptionEnum.self_pay]: 2970,
    [PaymentPeriodEnum.unknown]: 0,
  },
  [PaymentPeriodEnum.unknown]: {
    [PaymentOptionEnum.insurance]: 0,
    [PaymentOptionEnum.self_pay]: 0,
    [PaymentPeriodEnum.unknown]: 0,
  },
};

export const paymentPeriodOptionsWithPriceInsurance = Object.entries(PaymentPeriodEnum).map(([key, value]) => ({
  text: `${value} $${paymentPeriodPriceMap[value][PaymentOptionEnum.insurance]}`,
  value: key,
}));

export const paymentPeriodOptionsWithPriceSelfPay = Object.entries(PaymentPeriodEnum).map(([key, value]) => ({
  text:
    value === PaymentPeriodEnum.unknown
      ? value
      : `${value} $${paymentPeriodPriceMap[value][PaymentOptionEnum.self_pay]}`,
  value: key,
}));
