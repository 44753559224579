
import { Component, Prop, Vue } from 'vue-property-decorator';

import TimedCardText from '@/components/TimedCardText.vue';

@Component({
  components: {
    TimedCardText,
  },
})
export default class ProviderOutstandingActionsCard extends Vue {
  @Prop() private data!: [];
}
