export enum PaymentOptionEnum {
  unknown = 'Undecided',
  insurance = 'Insurance',
  self_pay = 'Self Pay',
}

export const availablePaymentOptions = Object.entries(PaymentOptionEnum).map(([key, value]) => ({
  text: value,
  value: key,
}));

export const availablePaymentOptionsNoUnknown = Object.entries(PaymentOptionEnum)
  .filter(([key]) => key !== 'unknown')
  .map(([key, value]) => ({
    text: value,
    value: key,
  }));
