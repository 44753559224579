export enum PrescriptionKindEnum {
  UNKNOWN = 'unknown',
  INITIAL = 'initial',
  RENEWAL = 'renewal',
  FORMULA_MODIFICATION = 'formula_modification',
}

export function displayPrescriptionKind(kind: PrescriptionKindEnum): string {
  switch (kind) {
    case PrescriptionKindEnum.UNKNOWN:
      return '';
    case PrescriptionKindEnum.INITIAL:
      return 'INITIAL';
    case PrescriptionKindEnum.RENEWAL:
      return 'RENEWAL';
    case PrescriptionKindEnum.FORMULA_MODIFICATION:
      return 'FORMULA MODIFICATION';
  }
}

export const prescriptionKindOptions = Object.freeze(
  Object.values(PrescriptionKindEnum)
    .filter((value) => value != PrescriptionKindEnum.UNKNOWN)
    .map((value) => ({
      value,
      text: displayPrescriptionKind(value),
    }))
);
