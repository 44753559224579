
import { showError } from '@/components/errors';
import { ClipboardService } from '@/services/ClipboardService';
import { isAdult } from '@/tools';
import {
  AnsweredQuestion,
  insuranceEligibilityMap,
  PatientProgressStageEnum,
  PatientType,
  PaymentOptionEnum,
  PaymentPeriodEnum,
  paymentPeriodPriceMap,
  StafferType,
  Timezone,
  type TimezoneKey,
} from '@/types';
import { getLaboratoryName } from '@/types/Laboratory';
import { isAfter, isValid } from 'date-fns';
import gql from 'graphql-tag';
import type { PropType } from 'vue';
import Vue from 'vue';
import { mapState } from 'pinia';
import { createSelectList, useStaffersStore } from '@/stores/staffers';
import BadgeDuplicatedPhoneNumber from '@/components/BadgeDuplicatedPhoneNumber.vue';
import { fileTypeFromStream, type FileTypeResult } from 'file-type';
import IsVip from '@/components/IsVip.vue';

export default Vue.extend({
  components: { IsVip, BadgeDuplicatedPhoneNumber },
  props: {
    patient: { type: Object as PropType<PatientType>, required: false },
    fullAddress: { type: Boolean, default: true, required: false },
    showTitle: { type: Boolean, default: true, required: false },
    showIdCard: { type: Boolean, default: false, required: false },
    showUnverifyIdCard: { type: Boolean, default: false, required: false },
    showVerifyIdCard: { type: Boolean, default: false, required: false },
  },
  data(): typeof this.$data & {
    doctors?: StafferType[];
    currentStaffer?: StafferType;
    cardLoadingError: boolean;
    cardIdsFileTypes: Record<string, string>;
  } {
    return {
      isCollapsed: false,
      loading: 0,
      cardLoadingError: false,
      cardIdsFileTypes: {},
    };
  },
  computed: {
    ...mapState(useStaffersStore, ['doctors', 'callAgents', 'currentStaffer']),
    idCards(): PatientType['idCards'] {
      return this?.patient?.idCards ?? [];
    },
    physicianId(): StafferType['id'] {
      return this.patient?.physician?.id || '';
    },
    callAgentId(): string {
      return this.patient?.callCenterAgent?.id || '';
    },
    doctorsOptions() {
      return createSelectList(this.doctors, this.currentStaffer, [this.physicianId]);
    },
    callAgentsOptions() {
      return createSelectList(this.callAgents, this.currentStaffer, [this.callAgentId]);
    },
    displayStage(): PatientProgressStageEnum {
      if (!this.patient) {
        return PatientProgressStageEnum.unknown;
      }

      return PatientProgressStageEnum[this.patient.progressStage as unknown as keyof typeof PatientProgressStageEnum];
    },
    addressStr(): string {
      if (!this.patient || !this.patient.shippingAddressStr) {
        return '';
      }

      return this.patient.shippingAddressStr;
    },
    addressState(): string {
      if (!this.patient || !this.patient.shippingAddress) {
        return '';
      }
      return this.patient.shippingAddress!.state || 'not provided';
    },
    insuranceEligibilityState(): string {
      if (!this.patient || !this.patient.insuranceEligibility) {
        return 'Unknown';
      }
      return insuranceEligibilityMap[this.patient.insuranceEligibility];
    },
    fullName(): string {
      if (this.patient && this.patient.preferredName) {
        return `${this.patient.firstName} ${this.patient.lastName} (${this.patient.preferredName})`;
      } else if (this.patient) {
        return `${this.patient.firstName} ${this.patient.lastName}`;
      } else {
        return '';
      }
    },
    patientTimezone(): Timezone | undefined {
      if (!this.patient?.state) {
        return;
      }

      return Timezone[this.patient.state as TimezoneKey];
    },
    isInMessagingWindow(): boolean {
      return this.$route.path.includes('/messaging');
    },
    showMessagingLink(): boolean {
      return !this.showIntercomLink && !this.isInMessagingWindow && !!this.patient?.chatId?.length;
    },
    showIntercomLink(): boolean {
      return (
        !this.isInMessagingWindow &&
        !!this.patient?.user?.intercomProfileUrl?.length &&
        this.patient?.user?.intercomProfileUrl.includes('intercom')
      );
    },
    stageStyle(): Partial<CSSStyleDeclaration> {
      const commonStyles: Partial<CSSStyleDeclaration> = {
        borderWidth: '1px',
        borderStyle: 'solid',
        padding: '0px 2px',
      };

      switch (this.displayStage) {
        case PatientProgressStageEnum.cancelled:
          return {
            ...commonStyles,
            backgroundColor: 'lightpink',
            borderColor: 'deeppink',
            color: 'darkred',
          };
        case PatientProgressStageEnum.subscription:
          return {
            ...commonStyles,
            backgroundColor: 'lightgreen',
            borderColor: 'darkgreen',
            color: 'darkgreen',
          };
        default:
          return {};
      }
    },
    guardianFullName(): string {
      if (this.patient.guardians.length) {
        return `${this.patient.guardians[0].firstName} ${this.patient.guardians[0].lastName}`;
      }
      if (this.patient?.guardianFirstName && this.patient?.guardianLastName) {
        return `${this.patient.guardianFirstName} ${this.patient.guardianLastName}`;
      }

      return '';
    },
    showGuardianTag(): boolean {
      return !isAdult(this.patient.dateOfBirth) && !!this.guardianFullName;
    },
    guardianTagText(): string {
      return this.showGuardianTag ? `Guardian - ${this.guardianFullName}` : '';
    },
    preferredPharmacyAddress(): string {
      if (this.patient.pharmacyAddressStr) {
        return this.patient.pharmacyAddressStr;
      }

      if (!this.patient.questionnaire) {
        return '';
      }

      const answeredQuestion: AnsweredQuestion = this.patient?.questionnaire?.answeredQuestions?.find(
        (answeredQuestion: AnsweredQuestion): boolean => answeredQuestion.question.id === 'oarqmmiulc'
      );

      return answeredQuestion?.displayAnswers?.[0] || '';
    },
    hasPreferredPharmacyAddress(): boolean {
      return !!this.preferredPharmacyAddress.length;
    },
    paymentPeriod(): PatientType['paymentPeriod'] {
      return this.patient?.paymentPeriod;
    },
    paymentPeriodWithPrice(): string {
      if (!this.paymentPeriod) {
        return '';
      }

      const selectedPaymentPeriod = PaymentPeriodEnum[this.paymentPeriod];
      if (selectedPaymentPeriod === PaymentPeriodEnum.unknown) {
        return '';
      }

      const selectedPaymentOption = PaymentOptionEnum[this.patient.user.preferredPaymentOption];
      if (selectedPaymentOption === PaymentOptionEnum.unknown) {
        return `${selectedPaymentPeriod}`;
      }

      return `${selectedPaymentPeriod} $${paymentPeriodPriceMap[selectedPaymentPeriod][selectedPaymentOption]}`;
    },
    hasPreclinicalConfirmed(): boolean {
      const date: Date = new Date(this.patient.preClinicalDataConfirmedAt);

      return isValid(date) && isAfter(date, new Date(2000, 1, 1));
    },
    progressStageHighlight(): string {
      const outcomes = {
        '017cf1c3-d09c-3ff9-3ea9-6ad5ad610876': 'SLIT Rx- Patient Undecided',
        '017cf1c3-d09c-2155-0cc9-27a63ac31f73': 'Reevaluation (Re-testing)',
      };

      if (
        !this.patient || // No Patient object
        !this.patient.notes?.length || // No Notes
        !['on_hold', 'testing'].includes(this.patient.progressStage) || // Different Progress Stage
        !(this.patient.notes[0]?.appointment?.outcomes ?? []).some(
          (
            { title } // Missing outcomes
          ) => Object.values(outcomes).includes(title)
        )
      ) {
        return '';
      }

      if (
        this.patient.progressStage === 'testing' &&
        this.patient.notes[0]?.appointment?.outcomes
          .map(({ title }) => title)
          .includes(outcomes['017cf1c3-d09c-2155-0cc9-27a63ac31f73'])
      ) {
        return 'Retesting';
      }

      return 'Undecided';
    },
  },
  methods: {
    toggleCollapsedState(event: Event): void {
      event.preventDefault();
      this.isCollapsed = !this.isCollapsed;
    },
    paymentOptionTitle(paymentOption: keyof typeof PaymentOptionEnum): PaymentOptionEnum {
      return PaymentOptionEnum[paymentOption];
    },
    getLaboratoryName: getLaboratoryName,
    async copyToClientBuffer(valueToCopy: string = ''): Promise<void> {
      if (!(await ClipboardService.write(valueToCopy))) {
        this.showToast('Copy to clipboard', 'Sorry, cannot copy', 'danger');
        return;
      }

      this.showToast('Copy to clipboard', 'Success!', 'success');
    },
    showToast(title: string, text: string, variant: string): void {
      this.$bvToast.toast(text, {
        autoHideDelay: 2000,
        title,
        toaster: 'b-toaster-top-right',
        variant,
      });
    },
    showPopover(popoverId: string): void {
      if (!popoverId) {
        return;
      }

      this.$root.$emit('bv::show::popover', popoverId);
    },
    hidePopover(popoverId: string): void {
      if (!popoverId) {
        return;
      }

      this.$root.$emit('bv::hide::popover', popoverId);
    },
    async onPhysicianSelect(physicianId: string) {
      this.loading++;
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($patientId: UUID!, $physicianId: UUID!) {
              setPatientPhysician(patientId: $patientId, physicianId: $physicianId) {
                status
              }
            }
          `,
          // Parameters
          variables: {
            patientId: this.patient.id,
            physicianId,
          },
        });
        this.$emit('physician-select');
      } catch (error) {
        showError(this, error.message);
      }

      this.loading--;
    },
    async onCallAgentSelect(callCenterAgentId: string) {
      this.loading++;
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($patientId: UUID!, $callCenterAgentId: UUID!) {
              setPatientCallCenterAgent(patientId: $patientId, callCenterAgentId: $callCenterAgentId) {
                status
              }
            }
          `,
          // Parameters
          variables: {
            patientId: this.patient.id,
            callCenterAgentId,
          },
        });
        this.$emit('call-agent-select');
      } catch (error) {
        showError(this, error.message);
      }

      this.loading--;
    },
    onClickPreClinicalSms(): void {
      this.$emit('sms', 'preclinical-sms', this.patient);
    },

    unverifyIdentity(): void {
      this.$emit('sms', 'preclinical-sms', this.patient);
    },

    onCardLoadingError(): void {
      this.cardLoadingError = true;
    },

    async checkIDCardFileTypes(): Promise<void> {
      if (!this.idCards?.length) {
        return;
      }

      for (const card of this.idCards) {
        if (card?.cardImageFront) {
          this.cardIdsFileTypes[card.cardImageFront] = (await this.getFileType(card.cardImageFront))?.ext;
        }

        if (card.cardImageBack) {
          this.cardIdsFileTypes[card.cardImageBack] = (await this.getFileType(card.cardImageBack))?.ext;
        }
      }
    },

    async getFileType(s3Link: string): Promise<FileTypeResult> {
      try {
        if (!s3Link?.length) {
          return;
        }

        // Fetch file from S3 bucket
        const response = await fetch(s3Link);
        return await fileTypeFromStream(response.body as any);
      } catch (e) {
        console.error(e);
        return;
      }
    },
  },
  watch: {
    idCards: {
      handler() {
        this.checkIDCardFileTypes();
      },
      immediate: true,
    },
  },
});
